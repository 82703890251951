import { plainApi } from '../api';
import { userDispatch } from 'react';
const api = plainApi();

/**
 * Initialise the Google OAuth
 */

async function handleCallBackResponse(response) {
  const JWTToken = response.credential;
  try {
    const signInProcess = await api.post('/auth/login', { token: JWTToken });

    if (signInProcess.status === 403 ) {
      window.alert('Restricted email domain, access forbidden. Please contact administrator.');
      localStorage.clear();
      window.location.reload();
    } else if (signInProcess.status === 201) {
      window.location.reload(); // reload the page for make api calls again in componentDidMount
      userDispatch({
        type: 'LOGIN_SUCCESS',
        isLoggedIn: true,
        isForbidden: false,
      });
    } else {
      userDispatch({
        type: 'LOGIN_FAILED',
        isLoggedIn: false,
        isForbidden: false,
      });
    }
  } catch (error) {
    if(JSON.stringify(error) ==='{}'){
      console.log('Login successfully');
    } else{
    window.alert('Restricted email domain, access forbidden. Please contact administrator.');
    if (!localStorage.getItem('cookie')) {
      localStorage.removeItem('expiry');
      localStorage.removeItem('image');
    }
    logout();
    window.location.reload();
    }
  }
  const imageUrl = decodeJwtResponseFromGoogleAPI(response.credential);
  localStorage.setItem('image', imageUrl.picture);
}
function decodeJwtResponseFromGoogleAPI(token) {
  let base64Url = token.split('.')[1];
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  let jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
  return JSON.parse(jsonPayload);
}

const initialiseGoogleAuthentication = () => {
  document.getElementById('google-script').addEventListener('load', () => {
    // google-script is loaded
    console.log('google script load finished');
  });
  //check if the user has an alternative token.
  try{
    //set interval
    if(!window.google){
      setTimeout(googleLoginFuction, 1000);
      //cancel the interval
    }else {
      googleLoginFuction();
    }
  }catch(e){
    setTimeout(googleLoginFuction, 1000);
    console.log('google not loaded');
  }

  function googleLoginFuction() {
    window.google && window.google.accounts.id.initialize({
      client_id: process.env.REACT_APP_SSO_CLIENT_ID,
      scope: process.env.REACT_APP_SSO_SCOPES,
      callback: handleCallBackResponse,
    });
  }
};

const logout = async () => {
  try {
    await api.post('/auth/logout');
    // Remove any remaining session tokens from previous local storage implementation.
    if (localStorage.getItem('token')) {
      localStorage.removeItem('token');
      localStorage.removeItem('expiry');
    }
    localStorage.removeItem('image');
    window.location.replace('https://accounts.google.com/Logout');

    window.google.accounts.id.revoke(window.google.accounts.oauth2.revoke());
    localStorage.removeItem('image');
    window.location.reload();
  } catch (error) {
    console.error('Logout Error', error);
  }
};

const extendUserSession = async () => {
  const res = await api.post('/auth/extend');
  window.location.reload();
  return res;
};

export { logout, initialiseGoogleAuthentication, extendUserSession };
